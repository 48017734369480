import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, Typography, makeStyles } from '@material-ui/core';

import Loader from '../Loader';

const useStyles = makeStyles(() => ({
  root: {
    height: 55,
  },
}));

const TablePlaceholder = ({ message, loading, columnsAmount }) => {
  const classes = useStyles();
  return (
    <TableRow className={classes.root}>
      <TableCell colSpan={columnsAmount}>
        {loading ? (
          <Loader fill isLoading />
        ) : (
          <Typography align="center">{message}</Typography>
        )}
      </TableCell>
    </TableRow>
  );
};

TablePlaceholder.propTypes = {
  message: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  columnsAmount: PropTypes.number.isRequired,
};

export default React.memo(TablePlaceholder);
