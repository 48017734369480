/* eslint-disable */
import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import useAuth from '../services/Auth/useAuth';

import Login from './Login';
import Dashboard from './Dashboard';
import InsuranceSubscriptions from './Insurance/InsuranceSubscriptions';
import MobileSubscriptions from './Mobile';
import RefinancingSubscriptions from './Refinancing';
import MobileDetails from './MobileDetails';

const Routes = () => (
  <Switch>
    <Route exact path="/login" component={Login} />
    <PrivateRoute exact path="/insurance" component={InsuranceSubscriptions} />
    <PrivateRoute exact path="/mobile" component={MobileSubscriptions} />
    <PrivateRoute exact path="/mobile/:phone" component={MobileDetails} />
    <PrivateRoute exact path="/refinancing" component={RefinancingSubscriptions} />
    <PrivateRoute path="/" component={Dashboard} />
  </Switch>
);

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={props =>
        auth.authorized ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        )
      }
    />
  );
};
export default Routes;
/* eslint-enable */
