import React from 'react';
// import PropTypes from 'prop-types';
import {
  Container,
  Grid,
  makeStyles,
  Breadcrumbs,
  Button,
  Typography,
} from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';

import Page from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import useGetMobileSubscription from '../../hooks/Mobile/GetMobileSubscription.query';
import useGetMobileSubscriptions from '../../hooks/Mobile/GetMobileSubscriptions.query';
import FullPageLoader from '../../components/FullPageLoader';
import useGetMobileInvoices from '../../hooks/Mobile/GetMobileInvoices.query';

import UserCard from './_components/UserCard';
import DetailsCard from './_components/DetailsCard/DetailsCard';
import TransferCard from './_components/TransferCard/TransferCard';
import MobileCard from './_components/MobileCard/MobileCard';
import InvoiceTable from './_components/InvoiceTable';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  details: {
    marginTop: 12,
  },
  connected: {
    marginTop: 30,
  },
  bread: {
    color: '#ccc',
    fontSize: 12,
  },
  button: {
    padding: 0,
    textTransform: 'none',
    fontWeight: 'normal',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      opacity: 0.8,
    },
  },
}));

const MobileDetails = () => {
  const classes = useStyles();
  const { phone } = useParams();
  const history = useHistory();
  const {
    data: mobileInvoices,
    loading: loadingMobileInvoices,
  } = useGetMobileInvoices(phone);
  const {
    data: mobileSubscription,
    loading: loadingMobileSubscription,
  } = useGetMobileSubscription(phone);
  const {
    data: mobileSubscriptions,
    loading: loadingMobileSubscriptions,
  } = useGetMobileSubscriptions(phone);

  const returnUser = () => {
    if (
      mobileSubscription &&
      mobileSubscription.result &&
      mobileSubscription.result.length
    ) {
      return mobileSubscription.result[0];
    }
    return {
      firstName: '',
      lastName: '',
      email: '',
      startDate: '',
    };
  };

  const returnDetails = () => {
    if (
      mobileSubscriptions &&
      mobileSubscriptions.result &&
      mobileSubscriptions.result.length
    ) {
      const filtered = mobileSubscriptions.result.filter(
        s => s.phoneNumber === phone,
      );
      if (filtered && filtered.length) {
        return filtered[0];
      }
    }
    return {
      failSync: true,
      phoneNumber: phone,
      transferUsed: 0,
      transferTotal: 0,
      subscriptionName: '',
    };
  };

  const returnOther = () => {
    if (
      mobileSubscriptions &&
      mobileSubscriptions.result &&
      mobileSubscriptions.result.length
    ) {
      const filtered = mobileSubscriptions.result.filter(
        s => s.phoneNumber !== phone,
      );
      if (filtered && filtered.length) {
        return filtered;
      }
    }
    return [];
  };

  return (
    <Page className={classes.root}>
      {!loadingMobileSubscription &&
      !loadingMobileSubscriptions &&
      !loadingMobileInvoices ? (
        <div>
          <Container maxWidth={false}>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator="›"
              className={classes.bread}
            >
              <Button
                role="button"
                color="inherit"
                className={classes.button}
                onClick={() => {
                  history.push('/mobile');
                }}
              >
                Mobile subscriptions
              </Button>
              <Typography color="textPrimary"> </Typography>
            </Breadcrumbs>
            <PageTitle title="Mobile subscription details" />
            <Grid container spacing={3} className={classes.details}>
              <Grid item lg={3} sm={3} xl={3} xs={12}>
                <UserCard user={returnUser()} />
              </Grid>
              <Grid item lg={3} sm={3} xl={3} xs={12}>
                <DetailsCard data={returnDetails()} user={returnUser()} />
              </Grid>
              <Grid item lg={3} sm={3} xl={3} xs={12}>
                <TransferCard data={returnDetails()} />
              </Grid>
            </Grid>
          </Container>
          {returnOther() && returnOther().length > 0 ? (
            <Container maxWidth={false} className={classes.connected}>
              <PageTitle title="Connected mobile subscriptions" />
              <Grid container spacing={3} className={classes.details}>
                {returnOther().map(s => (
                  <Grid
                    item
                    lg={3}
                    sm={3}
                    xl={3}
                    xs={12}
                    key={s.subscriptionId}
                  >
                    <MobileCard data={s} />
                  </Grid>
                ))}
              </Grid>
            </Container>
          ) : null}
          <Container maxWidth={false} className={classes.connected}>
            <PageTitle title="Invoices" />
            <InvoiceTable
              data={
                mobileInvoices && mobileInvoices.result
                  ? mobileInvoices.result
                  : []
              }
              loading={loadingMobileInvoices}
            />
          </Container>
        </div>
      ) : (
        <FullPageLoader windowed isLoading color="#fff" />
      )}
    </Page>
  );
};

MobileDetails.propTypes = {};

MobileDetails.defaultProps = {};

export default MobileDetails;
