import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import {
  Box,
  Card,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import TablePlaceholder from '../../../components/TablePlaceholder';
import DateConverter from '../../../components/DateConverter';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    marginBottom: 20,
    marginTop: 20,
  },
  card: {
    backgroundColor: theme.palette.background.light,
    '& .MuiTableCell-root': {
      borderBottom: '1px solid #000',
    },
  },
  tableCard: {
    '& MuiPaper-root': {
      paddingTop: '0 !important',
    },
  },
  currency: {
    marginLeft: 5,
    fontSize: 10,
  },
  selectDropdown: {
    color: '#fff',
    backgroundColor: theme.palette.background.navBar,
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.dark,
    },
  },
  paid: {
    color: theme.palette.notify.success,
    fontWeight: 'bold',
  },
  unpaid: {
    color: theme.palette.notify.warning,
    fontWeight: 'bold',
  },
}));

const InvoiceTable = ({ data, loading }) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box mt={3} className={classes.tableCard}>
      <Card className={classes.card}>
        <Box minWidth={1050} className={classes.card}>
          <Table className={classes.card}>
            <TableHead>
              <TableRow>
                <TableCell>Number</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Paid</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data ? (
                data.slice(page * limit, page * limit + limit).map(invoice => (
                  <TableRow hover key={invoice.id}>
                    <TableCell className={classes.number}>
                      {invoice.invoiceNumber || '-'}
                    </TableCell>
                    <TableCell>
                      <DateConverter date={invoice.startDate} type="day" />
                    </TableCell>
                    <TableCell>
                      <strong>
                        <CurrencyFormat
                          value={invoice.paidAmount || 0}
                          displayType="text"
                          thousandSeparator
                        />
                        <span className={classes.currency}>NOK</span>
                      </strong>
                    </TableCell>
                    {invoice.paidAmount > 0 ? (
                      <TableCell
                        className={
                          invoice.paid === 'PAID'
                            ? classes.paid
                            : classes.unpaid
                        }
                      >
                        {invoice.paid}
                      </TableCell>
                    ) : (
                      <TableCell>-</TableCell>
                    )}
                  </TableRow>
                ))
              ) : (
                <TablePlaceholder
                  loading={loading}
                  columnsAmount={4}
                  message="0 invoices"
                />
              )}
            </TableBody>
          </Table>
        </Box>
        <TablePagination
          component="div"
          count={data ? data.length : 0}
          SelectProps={{
            MenuProps: { classes: { paper: classes.selectDropdown } },
          }}
          classes={{ menuItem: classes.menuItem }}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={data && data.length ? page : 0}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      </Card>
    </Box>
  );
};

InvoiceTable.propTypes = {
  data: PropTypes.any,
  loading: PropTypes.bool,
};

InvoiceTable.defaultProps = {
  data: [],
  loading: true,
};

export default InvoiceTable;
