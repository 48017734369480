import React from 'react';
import './assets/styles/index.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { ToastProvider } from 'react-toast-notifications';
import { BrowserRouter } from 'react-router-dom';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';

import { version } from '../package.json';

import theme from './assets/styles/theme';
import ScrollToTop from './components/ScrollToTop';
import useAuth from './services/Auth/useAuth';
import Routes from './pages/Routes';
import Layout from './components/Layout';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

const queryCache = new QueryCache();

function App() {
  const auth = useAuth();
  console.log(`Portal version: ${version}`);
  return (
    <ThemeProvider theme={theme}>
      <ToastProvider placement="bottom-right">
        <ReactQueryCacheProvider queryCache={queryCache}>
          <CssBaseline />
          <BrowserRouter>
            <ScrollToTop />
            {auth.authorized ? <Layout /> : <Routes />}
          </BrowserRouter>
        </ReactQueryCacheProvider>
      </ToastProvider>
    </ThemeProvider>
  );
}

export default App;
