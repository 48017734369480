import { decode } from 'jsonwebtoken';
import moment from 'moment';

import { DOMAIN } from '../Config';
import Http from '../Http/Http';

const jwtCheck = token => {
  if (token !== null) {
    try {
      const { exp } = decode(token);
      if (moment.utc(exp * 1000).isAfter(moment.utc())) {
        return true;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      return false;
    }
  }
  return false;
};

const getStoredToken = () => {
  const token = localStorage.getItem(DOMAIN.tokenName);
  if (token && token !== 'null' && jwtCheck(token)) {
    return token;
  }
  return null;
};

const setToken = token => {
  if (token) {
    localStorage.setItem(DOMAIN.tokenName, token);
    Http.setAuthToken(token);
  }
};

const getStoredUser = () => {
  const user = localStorage.getItem(DOMAIN.userName);
  if (user && JSON.parse(user)) {
    return JSON.parse(user);
  }
  return null;
};

const setUser = user => {
  if (user) {
    localStorage.setItem(DOMAIN.userName, JSON.stringify(user));
  }
};

export { getStoredToken, setToken, jwtCheck, setUser, getStoredUser };
