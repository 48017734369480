import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

import Loader from '../../../components/Loader';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.background.light,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.textPrimary,
    height: 56,
    width: 56,
  },
}));

const SummaryBar = ({
  className,
  title,
  children,
  value,
  onGo,
  isLoading,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
      onClick={() => onGo()}
    >
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textPrimary" gutterBottom variant="h6">
              {title}
            </Typography>
            {isLoading ? (
              <Loader isLoading color="#fff" fill={false} />
            ) : (
              <Typography color="textPrimary" variant="h3">
                {value}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>{children}</Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

SummaryBar.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.number,
  children: PropTypes.node,
  onGo: PropTypes.func,
  isLoading: PropTypes.bool,
};

SummaryBar.defaultProps = {
  className: '',
  title: '',
  value: '',
  children: null,
  onGo: () => {},
  isLoading: false,
};

export default SummaryBar;
