import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  Smartphone as MobileIcon,
  BarChart as DashboardIcon,
  DollarSign as RefinancingIcon,
  Heart as InsuranceIcon,
} from 'react-feather';

import useAuth from '../../../../services/Auth/useAuth';

import NavItem from './_components/NavItem';

const items = [
  {
    href: '/dashboard',
    icon: DashboardIcon,
    title: 'Dashboard',
  },
  {
    href: '/mobile',
    icon: MobileIcon,
    title: 'Mobile',
  },
  {
    href: '/insurance',
    icon: InsuranceIcon,
    title: 'Insurance',
  },
  {
    href: '/refinancing',
    icon: RefinancingIcon,
    title: 'Refinancing',
  },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
    borderRight: '1px solid #000',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  name: {
    marginTop: 8,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const auth = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      bgcolor="background.light"
    >
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={null}
          to="/dashboard"
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {auth.user && auth.user.name ? auth.user.name : ''}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map(item => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
