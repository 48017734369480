import { useQuery } from 'react-query';

import Http from '../../services/Http/Http';
import { API } from '../../services/Config';
import useAuth from '../../services/Auth/useAuth';

const useGetAllMobileSubscriptions = (limit, page) => {
  const auth = useAuth();
  const fetch = async () => {
    const { data } = await Http.get(
      `${API.erate}/api/v2/cs/users?pageNumber=${page}&pageSize=${limit}`,
    );
    return data;
  };

  const { data, isLoading, error, refetch } = useQuery(
    'allMobileSubscriptions',
    fetch,
    {
      refetchOnWindowFocus: false,
    },
  );

  const err = JSON.parse(JSON.stringify(error));
  if (error && err && err.message === 'Request failed with status code 401') {
    auth.signOut();
  }

  return { data, loading: isLoading, refetch };
};

export default useGetAllMobileSubscriptions;
