import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import convertDate from '../../hooks/ConvertDate';

function DateConverter({ date, child, text, type }) {
  return child ? (
    <span>{convertDate(date, text, type)}</span>
  ) : (
    <Typography>{convertDate(date, text, type)}</Typography>
  );
}

DateConverter.propTypes = {
  date: PropTypes.any,
  child: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.string,
};

DateConverter.defaultProps = {
  date: '',
  child: false,
  text: '-',
  type: 'default',
};

export default DateConverter;
