import moment from 'moment';

const convertDate = (date = '', text = '-', type) => {
  let mappedDate = text;
  if (date && date !== '') {
    if (!moment(date).isValid()) {
      return mappedDate;
    }
    if (type === 'default') {
      mappedDate = moment(date).format('YYYY/MM/DD HH:mm:ss');
    } else {
      mappedDate = moment(date).format('YYYY/MM/DD');
    }
  }
  return mappedDate;
};

export default convertDate;
