import React from 'react';
// import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import logo from '../../../../assets/images/logo.svg';

import { useStyles } from './styles';

const SideContent = () => {
  const classes = useStyles();
  return (
    <div className={classes.imageBox}>
      <div className={classes.content}>
        <img src={logo} alt="logo" className={classes.image} />
        <Typography variant="h2" component="h3">
          Agent Portal
        </Typography>
      </div>
    </div>
  );
};

SideContent.propTypes = {};

SideContent.defaultProps = {};

export default SideContent;
