import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  imageBox: {
    backgroundImage: 'url(/assets/background.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: '#191919',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    marginTop: '-100px',
    textAlign: 'center',
  },
  image: {
    height: 100,
    width: 300,
  },
}));

export { useStyles };
