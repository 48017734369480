import { useQuery } from 'react-query';

import Http from '../../services/Http/Http';
import { API } from '../../services/Config';
import useAuth from '../../services/Auth/useAuth';

const useGetMobileSubscriptions = phone => {
  const auth = useAuth();
  const fetch = async () => {
    const { data } = await Http.get(
      `${API.erate}/api/v2/cs/users/${phone}/subscriptions`,
    );
    return data;
  };

  const { data, isLoading, error } = useQuery(
    ['mobileSubscriptions', { phone }],
    fetch,
    {
      refetchOnWindowFocus: false,
    },
  );

  const err = JSON.parse(JSON.stringify(error));
  if (error && err && err.message === 'Request failed with status code 401') {
    auth.signOut();
  }

  return { data, loading: isLoading };
};

export default useGetMobileSubscriptions;
