import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Card, CardContent, makeStyles } from '@material-ui/core';
import 'react-circular-progressbar/dist/styles.css';

import { formatBytes } from '../../../../utils/formatBytes';

import styles from './styles.module.scss';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.navBar,
    minHeight: 255,
  },
  avatar: {
    height: 100,
    width: 100,
  },
}));

const DetailsCard = ({ data, user }) => {
  const classes = useStyles();

  const convertToPhoneNumber = phone => {
    return `${phone.substr(0, 3)} ${phone.substr(3, 2)} ${phone.substr(5, 3)}`;
  };

  return (
    <Card className={clsx(classes.root)}>
      <CardContent>
        <div className={`content ${styles.content}`}>
          <div className={styles.mobileDetails}>
            <div className={styles.row}>
              <div className={styles.valueContainer}>
                <div className={styles.label}>Phone number</div>
                <div className={`${styles.value} ${styles.colored}`}>
                  {convertToPhoneNumber(data.phoneNumber)}
                </div>
              </div>
            </div>
            <div className={styles.sep} />
          </div>
          <div className={styles.mobileDetails}>
            <div className={styles.row}>
              <div className={styles.valueContainer}>
                <div className={styles.label}>Subscription name</div>
                <div className={`${styles.value} ${styles.trim}`}>
                  {user.subscriptionName || '-'}
                </div>
              </div>
            </div>
            <div className={styles.sep} />
            <div className={styles.row}>
              <div className={styles.valueContainer}>
                <div className={styles.label}>Data</div>
                <div className={styles.value}>
                  {formatBytes(data.transferTotal * 1000000, 2, null, true)}
                </div>
              </div>
              {data.failSync ? null : (
                <div className={styles.valueContainer}>
                  <div className={styles.label}>Used</div>
                  <div className={styles.value}>
                    {formatBytes(data.transferUsed * 1000000, 2, null, true)}
                  </div>
                </div>
              )}
            </div>
            <div className={styles.sep} />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

DetailsCard.propTypes = {
  data: PropTypes.any,
  user: PropTypes.any,
};

DetailsCard.defaultProps = {
  data: {},
  user: {},
};

export default DetailsCard;
