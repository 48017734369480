import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  container: {
    backgroundColor: theme.palette.background.default,
  },
  typography: {
    color: '#fff',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  paper: {
    padding: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    maxWidth: 450,
    margin: '-100px auto 0',
  },
  errorBox: {
    margin: '0 auto',
    color: theme.palette.primary.error,
    textAlign: 'center',
    '& p': {
      fontSize: '14px',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    '&:-webkit-autofill:focus': {
      WebkitTextFillColor: '#fff',
    },
    '&:-webkit-autofill:hover': {
      WebkitTextFillColor: '#fff',
    },
    '&:-internal-autofill-selected': {
      WebkitTextFillColor: '#fff',
    },
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px #242424 inset',
    },
  },
}));

export { useStyles };
