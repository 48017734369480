import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Divider,
  IconButton,
  Paper,
  TextField,
  Button,
  Breadcrumbs,
} from '@material-ui/core';
import { useToasts } from 'react-toast-notifications';
import SearchIcon from '@material-ui/icons/Search';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom';

import Page from '../../components/Page';
import TablePlaceholder from '../../components/TablePlaceholder';
import DateConverter from '../../components/DateConverter';
import PageTitle from '../../components/PageTitle';
import useGetAllMobileSubscriptions from '../../hooks/Mobile/GetAllMobileSubscriptions.query';
import useGetMobileSubscription from '../../hooks/Mobile/GetMobileSubscription.query';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    marginBottom: 20,
    marginTop: 20,
  },
  card: {
    backgroundColor: theme.palette.background.light,
    '& .MuiTableCell-root': {
      borderBottom: '1px solid #000',
    },
  },
  tableCard: {
    '& MuiPaper-root': {
      paddingTop: '0 !important',
    },
  },
  date: {
    color: '#ccc',
    '& p': {
      fontSize: 12,
    },
  },
  phone: {
    '& p': {
      fontSize: 14,
    },
  },
  rootForm: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    backgroundColor: theme.palette.background.navBar,
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    color: theme.palette.primary.main,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  selectDropdown: {
    color: '#fff',
    backgroundColor: theme.palette.background.navBar,
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.dark,
    },
  },
  row: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  bread: {
    color: '#ccc',
    fontSize: 12,
  },
  button: {
    padding: 0,
    textTransform: 'none',
    fontWeight: 'normal',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      opacity: 0.8,
    },
  },
  table: {
    overflow: 'auto',
  },
}));

const MobileSubscriptions = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [search, setSearch] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  const { data, loading: isLoading, refetch } = useGetAllMobileSubscriptions(
    limit,
    page > 0 ? page : 1,
  );

  const { data: searchData, refetch: searchRefetch } = useGetMobileSubscription(
    search,
    true,
  );

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = () => {
    if (search && search.length) {
      setIsSearching(true);
      searchRefetch();
      setPage(1);
    } else {
      addToast('Enter proper phone number', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const abortSearch = () => {
    setIsSearching(false);
    setSearch('');
    setPage(1);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const getCount = () => {
    if (
      isSearching &&
      searchData &&
      searchData.result &&
      searchData.result.length
    ) {
      return searchData.result.length;
    }

    if (!isSearching && data && data.total) {
      return data.total;
    }

    return 0;
  };

  const getPage = () => {
    if (isSearching && searchData && searchData.result) {
      return 0;
    }

    if (data && data.result && data.result.length) {
      return data.pageNumber;
    }

    return 0;
  };

  const handleSearchOnChange = e => {
    setSearch(e.target.value);
    if (!e.target.value || !e.target.value.length) {
      abortSearch();
    }
  };

  const returnData = () => {
    if (isSearching) {
      if (searchData && searchData.result && searchData.result.length) {
        return searchData.result.slice(0, limit).filter(s => s.erateId);
      }
      return null;
    }
    if (data && data.result && data.result.length) {
      return data.result.slice(0, limit).filter(s => s.erateId);
    }
    return null;
  };

  useEffect(() => {
    refetch();
  }, [page, limit, refetch]);

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="›"
          className={classes.bread}
        >
          <Button
            role="button"
            color="inherit"
            className={classes.button}
            onClick={() => {
              history.push('/dashboard');
            }}
          >
            Dashboard
          </Button>
          <Typography color="textPrimary"> </Typography>
        </Breadcrumbs>
        <PageTitle title="Mobile subscriptions" />
        <div className={classes.root}>
          <Box mt={3}>
            <Card className={classes.card}>
              <CardContent>
                <Box maxWidth={500}>
                  <Paper className={classes.rootForm}>
                    {(search && search.length) || isSearching ? (
                      <IconButton
                        className={classes.iconButton}
                        aria-label="menu"
                        type="button"
                        onClick={() => abortSearch()}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    ) : null}

                    <TextField
                      className={classes.input}
                      placeholder="Search subscription phone number"
                      inputProps={{ 'aria-label': 'search phone number' }}
                      onChange={handleSearchOnChange}
                      onKeyPress={handleKeyPress}
                      value={search}
                    />
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />
                    <IconButton
                      color="primary"
                      className={classes.iconButton}
                      type="button"
                      onClick={() => {
                        handleSearch();
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </div>
        <Box mt={3} className={classes.tableCard}>
          <Card className={classes.card}>
            <PerfectScrollbar>
              <Box className={`${classes.card} ${classes.table}`}>
                <Table className={`${classes.card} ${classes.table}`}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Subscription</TableCell>
                      <TableCell>Registration date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {returnData() ? (
                      returnData().map(subscription => (
                        <TableRow
                          hover
                          key={`${subscription.erateId}-${subscription.phoneNumber}`}
                          onClick={() =>
                            history.push(`/mobile/${subscription.phoneNumber}`)
                          }
                          className={classes.row}
                        >
                          <TableCell>
                            {subscription.firstName || ''}{' '}
                            {subscription.lastName || ''}
                          </TableCell>
                          <TableCell>{subscription.email || '-'}</TableCell>

                          <TableCell className={classes.phone}>
                            <Typography color="primary">
                              {subscription.phoneNumber || '-'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <strong>
                              {subscription.subscriptionName || '-'}
                            </strong>
                          </TableCell>
                          <TableCell className={classes.date}>
                            <DateConverter
                              date={subscription.startDate}
                              type="day"
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TablePlaceholder
                        loading={isLoading}
                        columnsAmount={5}
                        message="0 mobile subscriptions"
                      />
                    )}
                  </TableBody>
                </Table>
              </Box>
              <TablePagination
                component="div"
                count={getCount()}
                SelectProps={{
                  MenuProps: { classes: { paper: classes.selectDropdown } },
                }}
                classes={{ menuItem: classes.menuItem }}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleLimitChange}
                page={getPage()}
                rowsPerPage={limit}
                rowsPerPageOptions={[10, 25, 50, 100]}
              />
            </PerfectScrollbar>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default MobileSubscriptions;
