import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
// import PropTypes from 'prop-types';

const Copyrights = () => {
  return (
    <Typography variant="body2" color="textPrimary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://scoopr.no/">
        Scoopr
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

Copyrights.propTypes = {};

Copyrights.defaultProps = {};

export default Copyrights;
