import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
  Smartphone as MobileIcon,
  DollarSign as RefinancingIcon,
  Heart as InsuranceIcon,
} from 'react-feather';
import { useQuery } from 'react-query';

import Page from '../../components/Page';
import Http from '../../services/Http/Http';
import { API } from '../../services/Config';

import SummaryBar from './_components/SummaryBar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const history = useHistory();

  const fetchMobileSubscriptions = async () => {
    const { data } = await Http.get(`${API.erate}/api/v2/cs/users`);
    return data;
  };

  const { data, isLoading } = useQuery(
    'mobileSubscriptions',
    fetchMobileSubscriptions,
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <SummaryBar
              value={data && data.total ? data.total : 0}
              title="MOBILE SUBSCRIPTIONS"
              onGo={() => {
                history.push('/mobile');
              }}
              isLoading={isLoading}
            >
              <MobileIcon />
            </SummaryBar>
          </Grid>
          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <SummaryBar
              value={0}
              title="INSURANCE SUBSCRIPTIONS"
              onGo={() => {
                history.push('/insurance');
              }}
              isLoading={isLoading}
            >
              <InsuranceIcon />
            </SummaryBar>
          </Grid>
          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <SummaryBar
              value={0}
              title="REFINANCING SUBSCRIPTIONS"
              onGo={() => {
                history.push('/refinancing');
              }}
              isLoading={isLoading}
            >
              <RefinancingIcon />
            </SummaryBar>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

Dashboard.propTypes = {};

Dashboard.defaultProps = {};

export default Dashboard;
