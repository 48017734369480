import axios from 'axios';

import { API } from '../Config';

class Http {
  constructor() {
    this.axios = axios.create({
      baseURL: API.uri,
      header: {
        'Content-Type': 'application/json',
      },
    });

    this.unpackRestMethods();
  }

  unpackRestMethods() {
    const methods = ['get', 'post', 'patch', 'delete', 'put', 'options'];
    methods.forEach(method => {
      this[method] = this.axios[method];
    });
  }

  setAuthToken = token => {
    this.axios.defaults.headers.Authorization = `Bearer ${token}`;
  };

  removeAuthToken = () => {
    delete this.axios.defaults.headers.Authorization;
  };
}

export default new Http();
