import { useQuery } from 'react-query';
import _ from 'lodash';

import Http from '../../services/Http/Http';
import { API } from '../../services/Config';
import useAuth from '../../services/Auth/useAuth';

const useGetMobileInvoices = phone => {
  const auth = useAuth();
  const fetch = async () => {
    const { data } = await Http.get(
      `${API.erate}/api/v2/cs/users/${phone}/invoices`,
    );
    return data;
  };

  const { data: rawData, isLoading, error } = useQuery(
    ['mobileInvoices', { phone }],
    fetch,
    {
      refetchOnWindowFocus: false,
    },
  );

  const err = JSON.parse(JSON.stringify(error));
  if (error && err && err.message === 'Request failed with status code 401') {
    auth.signOut();
  }

  const data = {
    result:
      rawData && rawData.result
        ? _.orderBy(rawData.result, ['startDate'], ['desc'])
        : [],
  };

  return { data, loading: isLoading };
};

export default useGetMobileInvoices;
