import { useQuery } from 'react-query';

import Http from '../../services/Http/Http';
import { API } from '../../services/Config';
import useAuth from '../../services/Auth/useAuth';

const useGetMobileSubscription = (phone, disabled) => {
  const auth = useAuth();
  const fetch = async () => {
    const { data } = await Http.get(`${API.erate}/api/v2/cs/users/${phone}`);
    return data;
  };

  const { data, isLoading, error, refetch } = useQuery(
    ['mobileSubscription', { phone }],
    fetch,
    {
      enabled: !disabled,
      refetchOnWindowFocus: false,
    },
  );

  const err = JSON.parse(JSON.stringify(error));
  if (error && err && err.message === 'Request failed with status code 401') {
    auth.signOut();
  }

  return { data, loading: isLoading, refetch };
};

export default useGetMobileSubscription;
