import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const LoginInput = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#fff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#a3a3a3',
    },
    '& .MuiFormLabel-root': {
      color: '#a3a3a3',
    },
    '& .MuiOutlinedInput-root': {
      color: '#a3a3a3',
      '& fieldset': {
        borderColor: '#a3a3a3',
        color: '#a3a3a3',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fff',
      },
    },
  },
})(TextField);

export default LoginInput;
