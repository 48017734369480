const env = process.env.REACT_APP_STAGE || 'release';
const config = {
  release: {
    api: {
      uri: '',
      loginUri: 'https://api.dev.hudya.io/v2.0/identity/identify/',
      // erate: 'https://erate.payr.acaisoft.dev',
      erate: 'https://erate-gateway-rjyogppema-ew.a.run.app',
    },
  },
  dev: {
    api: {
      uri: '',
      loginUri: 'https://krystian.dev.hudya.io/v2.0/identity/identify/',
      // erate: 'https://erate.payr.acaisoft.dev',
      erate: 'https://erate-gateway-rjyogppema-ew.a.run.app',
    },
  },
};

export const API = {
  uri: '',
  loginUri: config[env].api.loginUri,
  erate: config[env].api.erate,
};

export const DOMAIN = {
  tokenName: 'SCOOPR_CS_PORTAL_TOKEN',
  userName: 'SCOOPR_CS_PORTAL_USER',
};
