import { createMuiTheme } from '@material-ui/core/styles';

import shadows from './shadows';
import typography from './typography';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    text: {
      default: '#fff',
      primary: '#fff',
    },
    textColor: '#fff',
    textPrimary: '#fff',
    textSecondary: '#ccc',
    primary: {
      main: '#f75369',
      error: '#ff282c',
    },
    secondary: {
      main: '#1e1e1e',
    },
    error: {
      main: '#ff282c',
    },
    notify: {
      error: '#ff282c',
      warning: '#ffcc00',
      success: '#99cc33',
    },
    background: {
      default: '#242424',
      navBar: '#383838',
      topBar: '#1e1e1e',
      light: '#303030',
    },
  },
  shadows,
  typography,
});

export default theme;
