import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Card, CardContent, makeStyles } from '@material-ui/core';
import moment from 'moment';
import 'react-circular-progressbar/dist/styles.css';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';

import { formatBytes } from '../../../../utils/formatBytes';

import styles from './styles.module.scss';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.navBar,
    minHeight: 255,
  },
  avatar: {
    height: 100,
    width: 100,
  },
}));

const FAILSYNCVALUES = {
  used: 0,
  total: 100000 * 1000000,
};

const TransferCard = ({ data }) => {
  const classes = useStyles();

  const getPercent = (used, total) => {
    return ((used * 100) / total).toFixed(0);
  };

  const getDaysLeft = () => {
    const days = moment().daysInMonth();
    const day = +moment().format('DD');
    return days - day;
  };

  return (
    <Card className={clsx(classes.root)}>
      <CardContent>
        <div className={`content ${styles.content}`}>
          <div className={styles.mobileDetails}>
            <div className={styles.row}>
              <div className={styles.valueContainer}>
                <div className={styles.label}>
                  Used in{' '}
                  <span className={styles.capitalize}>
                    {moment().format('MMM')}
                  </span>
                </div>
              </div>
              <div className={`${styles.valueContainer} ${styles.valueRight}`}>
                <div className={styles.label}>{getDaysLeft()} days left</div>
              </div>
            </div>
          </div>
          <div className={styles.subscription}>
            {data.failSync ? (
              <div className={styles.offlineText}>
                <p>Temporarily unavailable</p>
              </div>
            ) : null}
            <div
              className={`${styles.progress} ${
                data.failSync ? styles.blur : ''
              }`}
            >
              <div style={{ width: 120, height: 120 }}>
                <CircularProgressbarWithChildren
                  value={getPercent(
                    data.failSync ? FAILSYNCVALUES.used : data.transferUsed,
                    data.failSync ? FAILSYNCVALUES.total : data.transferTotal,
                  )}
                  styles={buildStyles({
                    pathColor: '#f75369',
                  })}
                >
                  <div className={styles.used}>
                    {formatBytes(
                      data.failSync
                        ? FAILSYNCVALUES.used
                        : data.transferUsed * 1000000,
                      2,
                      null,
                      true,
                    )}
                  </div>
                  <div className={styles.usedDesc}>used</div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
            <div
              className={`${styles.progressDetails} ${
                data.failSync ? styles.blur : ''
              }`}
            >
              <div className={styles.left}>
                total {formatBytes(data.transferTotal * 1000000, 2, null, true)}
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

TransferCard.propTypes = {
  data: PropTypes.any,
};

TransferCard.defaultProps = {
  data: {},
};

export default TransferCard;
