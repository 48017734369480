import React, { useEffect, useReducer, createContext } from 'react';
import PropTypes from 'prop-types';

import { DOMAIN } from '../Config';
import FullPageLoader from '../../components/FullPageLoader';

import {
  getStoredToken,
  jwtCheck,
  setToken,
  setUser,
  getStoredUser,
} from './authService';

const authActions = {
  setUser: 'SET_USER',
  setLoading: 'TOGGLE_LOADING',
  setAuth: 'SET_AUTHORIZED',
  setToken: 'SET_TOKEN',
  setIsInit: 'SET_IS_INIT',
  signOut: 'SIGN_OUT',
};

const reducer = (state, action) => {
  switch (action.type) {
    case authActions.setUser:
      return {
        ...state,
        user: action.payload.user,
        isInitiallyLoading: false,
        isLoading: {
          logOut: false,
        },
      };
    case authActions.setIsInit:
      return {
        ...state,
        isInitiallyLoading: action.payload.isInitiallyLoading,
      };
    case authActions.setAuth:
      return {
        ...state,
        authorized: action.payload.authorized,
      };
    case authActions.setToken:
      return {
        ...state,
        token: action.payload.token,
      };
    case authActions.signOut:
      return {
        ...state,
        token: action.payload.token,
        authorized: action.payload.authorized,
        user: action.payload.user,
      };
    case authActions.setLoading:
      return {
        ...state,
        isLoading: { ...state.isLoading, ...action.payload },
      };
    default:
      throw new Error(`No case for type ${action.type} found.`);
  }
};

const AuthContext = createContext(undefined);
const { Consumer: AuthConsumer } = AuthContext;

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, {
    isAuthenticated: false,
    token: null,
    user: {
      name: '',
      identity: null,
      authLevel: 0,
    },
    isInitiallyLoading: true,
    isLoading: {
      logOut: false,
      refreshToken: false,
    },
  });

  const toggleLoading = (isLoading, action) => {
    const pay = {};
    pay[action] = isLoading;
    dispatch({
      type: authActions.setLoading,
      payload: {
        ...pay,
      },
    });
  };

  const runDispatch = (type, payload) => {
    dispatch({ type, payload });
  };

  const signIn = data => {
    runDispatch(authActions.setToken, { token: data.jwt });
    setToken(data.jwt);
    const us = {
      identity: data.identity,
      name: data.name,
      authLevel: data.authLevel,
    };
    runDispatch(authActions.setUser, {
      user: us,
    });
    setUser(us);
    runDispatch(authActions.setAuth, { authorized: true });
  };

  const signOut = () => {
    localStorage.removeItem(DOMAIN.tokenName);
    localStorage.removeItem(DOMAIN.userName);
    toggleLoading(true, 'signOut');
    runDispatch(authActions.signOut, {
      token: null,
      user: null,
      isAuthenticated: false,
    });

    toggleLoading(false, 'signOut');
  };

  const getToken = () => {
    if (!state.isInitiallyLoading) {
      if (jwtCheck(state.token)) {
        return state.token;
      }
    }
    return null;
  };

  useEffect(() => {
    setToken(state.token);
  }, [state.token]);

  useEffect(() => {
    // init
    const token = getStoredToken();
    const us = getStoredUser();
    runDispatch(authActions.setToken, { token });
    setToken(token);
    if (token && us) {
      runDispatch(authActions.setUser, { user: us });
      runDispatch(authActions.setAuth, { authorized: true });
    } else {
      signOut();
    }
    runDispatch(authActions.setIsInit, { isInitiallyLoading: false });
    // eslint-disable-next-line
  }, []);

  const value = {
    authorized: state.authorized,
    token: state.token,
    user: state.user,
    signIn,
    signOut,
    getToken,
    isLoading: state.isLoading,
  };

  return !state.isInitiallyLoading ? (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  ) : (
    <FullPageLoader color="#fff" isLoading />
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};

AuthProvider.defaultProps = {
  children: null,
};

export { AuthContext, AuthConsumer, AuthProvider };
