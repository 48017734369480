export const formatBytesMode = {
  VALUE: 'value',
  TYPE: 'type',
};

export function formatBytes(value, precision, modeValue, mib) {
  const data = {
    value: '0',
    type: 'Bytes',
  };
  if (value > 0) {
    const c = mib ? 1000 : 1024;
    const d = precision || 2;
    const type = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const f = Math.floor(Math.log(value) / Math.log(c));
    const g = c ** f;
    data.value = parseFloat((value / g).toFixed(d));
    data.type = type[f];
  }

  switch (modeValue) {
    case formatBytesMode.VALUE:
      return data.value;
    case formatBytesMode.TYPE:
      return data.type;
    default:
      return `${data.value} ${data.type}`;
  }
}
