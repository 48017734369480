import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: 20,
  },
}));

const PageTitle = ({ title }) => {
  const classes = useStyles();
  return (
    <Typography color="textPrimary" className={classes.root}>
      {title}
    </Typography>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

PageTitle.defaultProps = {};

export default PageTitle;
