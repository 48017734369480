import React from 'react';

import logoImg from '../../assets/images/logo.svg';

const Logo = props => {
  return (
    <img
      alt="Logo"
      src={logoImg}
      {...props}
      style={{ height: 25, marginTop: 10 }}
    />
  );
};

export default Logo;
