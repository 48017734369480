// import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Avatar,
  Paper,
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import useAuth from '../../services/Auth/useAuth';
import Http, { transformers } from '../../services/Http';
import { API } from '../../services/Config';

import Copyrights from './_components/Copyrights';
import { useStyles } from './styles';
import SideContent from './_components/SideContent';
import LoginInput from './_components/LoginInput';

// "email": "testidentityuser@test.com",
// "password": "Banan1234"

const Login = () => {
  const classes = useStyles();
  const auth = useAuth();
  const history = useHistory();
  const { handleSubmit, register, errors } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const [error, setError] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);

  const signIn = async data => {
    setError(null);
    setLoginLoading(true);
    try {
      const res = await Http.post(API.loginUri, { ...data });
      setLoginLoading(false);
      auth.signIn(res.data);
      history.push('/');
    } catch (ex) {
      const errorContent = transformers.prepareResponseError(ex);
      console.log('ex', errorContent);
      setError(errorContent.message.detail);
      setLoginLoading(false);
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7}>
        <SideContent />
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        className={classes.container}
      >
        <div className={classes.content}>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" color="textPrimary">
              Sign in
            </Typography>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit(data => signIn(data))}
            >
              <LoginInput
                color="secondary"
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                type="email"
                autoFocus
                autoComplete="email"
                inputRef={register({
                  required: {
                    value: true,
                    message: 'You must enter your email',
                  },
                })}
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : null}
                inputProps={{ className: classes.input }}
              />
              <LoginInput
                color="secondary"
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                inputRef={register({
                  required: {
                    value: true,
                    message: 'You must enter your password',
                  },
                })}
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : null}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {loginLoading ? (
                  <CircularProgress size={24} color="#fff" />
                ) : (
                  'Sign In'
                )}
              </Button>
              {error ? (
                <Box mt={5} className={classes.errorBox}>
                  <Typography>{error}</Typography>
                </Box>
              ) : null}
              <Box mt={5}>
                <Copyrights />
              </Box>
            </form>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

Login.propTypes = {};

Login.defaultProps = {};

export default Login;
