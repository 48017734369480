import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Typography,
  makeStyles,
} from '@material-ui/core';

import Tooltip from '../../../components/Tooltip/Tooltip';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.navBar,
    minHeight: 255,
  },
  avatar: {
    height: 100,
    width: 100,
    marginBottom: 20,
  },
  email: {
    color: theme.palette.primary.main,
    marginBottom: 10,
  },
  dateText: {
    fontSize: 12,
  },
  dotted: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: 200,
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
}));

const UserCard = ({ className, user, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Avatar className={classes.avatar} src={null} />
          <Tooltip value={`${user.firstName} ${user.lastName}`}>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h3"
              className={classes.dotted}
            >
              {user.firstName} {user.lastName}
            </Typography>
          </Tooltip>
          <Typography className={classes.email} variant="body1">
            {user.email}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

UserCard.propTypes = {
  className: PropTypes.string,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    startDate: PropTypes.any,
    email: PropTypes.string,
  }),
};

UserCard.defaultProps = {
  className: '',
  user: {
    firstName: '',
    lastName: '',
    startDate: '',
    email: '',
  },
};

export default UserCard;
