import React from 'react';
import MoonLoader from 'react-spinners/MoonLoader';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Loader = ({ isLoading, color, fill }) => {
  const override = css`
    display: block;
    margin: 0 auto;
  `;

  return (
    <div className={fill ? styles.loaderContainer : ''}>
      <MoonLoader
        css={fill ? override : null}
        size={15}
        color={color || '#fff'}
        loading={isLoading}
      />
    </div>
  );
};

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  color: PropTypes.string,
  fill: PropTypes.bool,
};

Loader.defaultProps = {
  color: undefined,
  fill: false,
};

export default Loader;
