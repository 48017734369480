import React from 'react';
import RingLoader from 'react-spinners/RingLoader';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const FullPageLoader = ({ isLoading, color, windowed }) => {
  const override = css`
    display: block;
    margin: 0 auto;
  `;
  const window = css`
    display: block;
    margin: 0 auto;
    right: -100px;
  `;
  return (
    <div className={styles.loaderContainer}>
      <RingLoader
        css={windowed ? window : override}
        size={60}
        color={color || '#000'}
        loading={isLoading}
      />
    </div>
  );
};

FullPageLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  windowed: PropTypes.bool,
  color: PropTypes.string,
};

FullPageLoader.defaultProps = {
  color: undefined,
  windowed: false,
};

export default FullPageLoader;
