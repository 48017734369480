import React, { useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Search as SearchIcon } from 'react-feather';
import moment from 'moment';

import Page from '../../components/Page';
import TablePlaceholder from '../../components/TablePlaceholder';
import PageTitle from '../../components/PageTitle';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    marginBottom: 20,
    marginTop: 20,
  },
  card: {
    backgroundColor: theme.palette.background.light,
    '& .MuiTableCell-root': {
      borderBottom: '1px solid #000',
    },
  },
  tableCard: {
    '& MuiPaper-root': {
      paddingTop: '0 !important',
    },
  },
  rootForm: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    backgroundColor: theme.palette.background.navBar,
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    color: theme.palette.primary.main,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  selectDropdown: {
    color: '#fff',
    backgroundColor: theme.palette.background.navBar,
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.dark,
    },
  },
  bread: {
    color: '#ccc',
    fontSize: 12,
  },
  button: {
    padding: 0,
    textTransform: 'none',
    fontWeight: 'normal',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      opacity: 0.8,
    },
  },
}));

const InsuranceSubscriptions = () => {
  const classes = useStyles();
  const history = useHistory();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const [subscriptions] = useState([]);

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="›"
          className={classes.bread}
        >
          <Button
            role="button"
            color="inherit"
            className={classes.button}
            onClick={() => {
              history.push('/dashboard');
            }}
          >
            Dashboard
          </Button>
          <Typography color="textPrimary"> </Typography>
        </Breadcrumbs>
        <PageTitle title="Insurance subscriptions" />
        <div className={classes.root}>
          <Box mt={3}>
            <Card className={classes.card}>
              <CardContent>
                <Box maxWidth={500}>
                  <Paper className={classes.rootForm}>
                    <TextField
                      className={classes.input}
                      placeholder="Search insurance"
                      inputProps={{ 'aria-label': 'search phone number' }}
                    />
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />
                    <IconButton
                      color="primary"
                      className={classes.iconButton}
                      type="button"
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </div>
        <Box mt={3} className={classes.tableCard}>
          <Card className={clsx(classes.root, classes.card)}>
            <Box minWidth={1050} className={classes.card}>
              <Table className={classes.card}>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Insurance</TableCell>
                    <TableCell>Registration date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subscriptions.length ? (
                    subscriptions.slice(0, limit).map(subscription => (
                      <TableRow hover key={subscription.id}>
                        <TableCell>{subscription.name}</TableCell>
                        <TableCell>{subscription.email}</TableCell>
                        <TableCell>{subscription.phone}</TableCell>
                        <TableCell>{subscription.insurance}</TableCell>
                        <TableCell>
                          {moment(subscription.createdAt).format('DD/MM/YYYY')}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TablePlaceholder
                      loading={false}
                      columnsAmount={5}
                      message="0 insurance subscriptions"
                    />
                  )}
                </TableBody>
              </Table>
            </Box>
            <TablePagination
              component="div"
              SelectProps={{
                MenuProps: { classes: { paper: classes.selectDropdown } },
              }}
              classes={{ menuItem: classes.menuItem }}
              count={subscriptions.length}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default InsuranceSubscriptions;
